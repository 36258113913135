import React from 'react';
import VideoPlayer from '../../../VideoPlayer';
import SportLasChat from '../../../chat/SportLasChat';
import { Row, Col, Descriptions, Button, Select, Alert, message, Popover, Tag, Avatar, Tooltip, Space, Typography } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, ClockCircleOutlined, KeyOutlined, CopyOutlined, ApiOutlined } from '@ant-design/icons';
import LoginAPI from '../../../../api/user';
import AnchorAPI from '../../../../api/Sport/anchor';
import ManagerAPI from '../../../../api/Sport/manager';
import LiveAPI from '../../../../api/Sport/live';
import MuteAPI from '../../../../api/Sport/mute';
import moment from 'moment';
import { setUser } from '../../../../store/user/action';
import { storeUserInfo, getUserInfo, getLanguage } from '../../../../helper/helper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Socket from '../../../../helper/sportSocket';
import ActionMenu from './actionMenu';
import MuteList from './MuteList';

const { Option } = Select
const { Title } = Typography

class Live extends React.Component {

  state = {
    push: {},
    myEvent: [],
    eventId: 0,
    eventLoading: false,
    startLiveLoading: false,
    eventTitle: '',
    streamURL: {},
    showVideoPlayer: true,
    showVideoAlert: false,
    language: 'zh-TW',
    customerData: {},
    showMenu: false,
    showMuteList: false,
    managerList: [],
    muteList: [],
    // las socket
    lasIsLogin: false,
    lasMsg: [],
    lasHeart: 0,
    lasSocket: null,
    isReconnectVisible: false,
  };

  componentWillMount() {
    const language = getLanguage();
    if (language) {
      this.setState({ language });
    }

    this.getManagerList(this.props.user.id);

    if (this.props.user.liveRoomIsBegin) {
      this.setState({
        eventTitle: this.props.user.eventName,
        streamURL: this.props.user.streamURL,
        push: this.props.user.push
      });
      if (!this.state.lasSocket) {
        this.setState({ lasSocket: new Socket(this, this.props.user) });
      }
    } else {
      this.getPushUrl();
      this.getMyAnchor();
    }
  }

  getManagerList = async (Id) => {
    const result = await ManagerAPI.managerList(Id);
    if (result.status === 200) {
      this.setState({ managerList: result.data });
    }
  };

  onLanguageChange = (language) => {
    window.sessionStorage.setItem('language', language);
    this.setState({ language });
  };

  getPushUrl = async () => {
    const result = await LoginAPI.pushUrl();

    if (result.status === 200) {
      this.setState({ push: result.data });
    } else {
      console.error(result);
    }
  };

  getMyAnchor = async () => {
    this.setState({ eventLoading: true });
    const result = await AnchorAPI.reserveEvent(
      this.props.user.merchant,
      moment(new Date()).set({ hour: 0, minute: 0, second: 0 }).utc().format('YYYY-MM-DD HH:mm:ss'),
      null
    );

    if (result.status === 200) {
      const vaule = [...result.data];
      vaule.forEach((even) => {
        even["eventName"] =
          `${even.homeTeamName}${this.props.intl.formatMessage({ id: 'SportLive.main' })}
         vs
         ${even.guestTeamName}${this.props.intl.formatMessage({ id: 'SportLive.guest' })}`;
      });

      const toDay = vaule.filter(e => e.status !== 'Resulted');
      this.setState({ myEvent: toDay, eventLoading: false });
    }
  };

  onChangeEventId = (id) => {
    this.setState({ eventId: id });
  };

  startLive = async () => {
    if (this.state.eventId === 0) {
      message.error(this.props.intl.formatMessage({ id: 'SportLive.seletcEvevt' }));
      return;
    }

    await LiveAPI.deleteLive();
    this.getStreamURL();
  };

  stopLive = async (deleteLive) => {
    if (deleteLive) await LiveAPI.deleteLive()

    this.onLiveChange(false);
    this.getPushUrl();
    this.getMyAnchor();
    this.state.lasSocket.disconnect();
    this.setState({ eventId: 0, lasMsg: [] });
  };

  getStreamURL = async () => {
    const result = await LiveAPI.postLive({
      gameId: this.state.eventId
    });

    if (result.status === 200) {
      this.setState({ eventTitle: result.data.game.title, streamURL: result.data.streamURL });
      this.onLiveChange(true);
      if (!this.state.lasSocket) {
        this.setState({ lasSocket: new Socket(this, this.props.user) });
      }
      this.state.lasSocket.connect();
    }
  };

  onLiveChange = (bool) => {
    const sessionStorage = getUserInfo();
    const user = sessionStorage.user;
    user.liveRoomIsBegin = bool;
    user.eventName = bool ? this.state.eventTitle : '';
    user.streamURL = bool ? this.state.streamURL : {};
    user.push = bool ? this.state.push : {};
    user.accessToken = sessionStorage.accessToken;
    storeUserInfo(user);
    setUser(user);
  };

  onChangeScreen = () => {
    this.setState({ showVideoPlayer: !this.state.showVideoPlayer, showVideoAlert: false });
  };

  sendMessage = (msg) => {
    this.state.lasSocket.sendMessage(msg);
  };

  flvOnError = (errorType, errorDetail, errorInfo, flvjs) => {
    this.setState({ showVideoPlayer: false, showVideoAlert: true });
  };

  onCopyUrl = () => {
    this.copyText(this.state.push.url);
    message.success('Copy Success!');
  };

  onCopyKey = () => {
    this.copyText(this.state.push.key);
    message.success('Copy Success!');
  };

  copyText = (text) => {
    var clip_area = document.createElement('textarea');
    clip_area.textContent = text;

    document.body.appendChild(clip_area);
    clip_area.select();
    document.execCommand('copy');

    document.body.removeChild(clip_area);
  };

  onClickItem = (item) => {
    this.setState({ showMenu: true, customerData: item });
  };

  getMuteList = async () => {
    const result = await MuteAPI.muteList();
    if (result.status === 200) {
      this.setState({ muteList: result.data.mute, showMuteList: true });
    }
  };

  tranColor = (name = '') => {

    var str = '';
    for (var i = 0; i < name.length; i++) {
      str += parseInt(name[i].charCodeAt(0), 10).toString(16);
    }
    return '#' + str.slice(1, 4);
  };

  onAirDataLayout = () => (
    <div style={{ marginTop: '12px', padding: '12px' }}>
      <Row type="flex" justify="center" align="middle">
        <Col>
          <Descriptions bordered style={{ textAlign: 'center', backgroundColor: 'white', padding: '12px' }}>
            <Descriptions.Item style={{ textAlign: 'center' }} span={3}
              label={this.props.intl.formatMessage({ id: 'SportLive.liveEvent' })}>
              <Select style={{ width: '100%' }} loading={this.state.eventLoading} onChange={this.onChangeEventId}>
                {this.state.myEvent.map(event => (
                  <Option value={event.id}>
                    {moment(new Date(event.startAt)).format('YYYY-MM-DD HH:mm ')}
                    {event.eventName}
                  </Option>
                ))}
              </Select>
              <div style={{ color: 'red', fontSize: 12, marginTop: 4, textAlign: 'left' }}>{this.props.intl.formatMessage({ id: 'SportLive.seletcEventLimitNotice' })}</div>
            </Descriptions.Item>
            <Descriptions.Item style={{ textAlign: 'center' }} span={3}
              label={this.props.intl.formatMessage({ id: 'SportLive.streamUrl' })}>{this.state.push.url}
              <Button icon={<CopyOutlined />} style={{ marginLeft: '12px', float: 'right' }} onClick={this.onCopyUrl} type='dashed' />
            </Descriptions.Item>
            <Descriptions.Item style={{ textAlign: 'center' }}
              label={this.props.intl.formatMessage({ id: 'SportLive.streamKey' })}>{this.state.push.key}
              <Button icon={<CopyOutlined />} style={{ marginLeft: '12px', float: 'right' }} onClick={this.onCopyKey} type='dashed' />
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle" style={{ marginTop: '12px' }}>
        <Alert message={this.props.intl.formatMessage({ id: 'SportLive.tooltip' })} type="info" style={{ width: '40%', textAlign: 'center' }} />
      </Row>
      <Row type="flex" justify="center" align="middle" style={{ marginTop: '20px' }}>
        <Button size='large' shape="round" type="primary" loading={this.state.startLiveLoading} onClick={this.startLive}>
          {this.props.intl.formatMessage({ id: 'SportLive.startLive' })}
        </Button>
      </Row>
    </div>
  )

  content = () => {
    if (!this.state.push)
      return
    return (
      <>
        <p><Tag color="orange">{this.props.intl.formatMessage({ id: 'SportLive.streamUrl' })}</Tag>
          {this.state.push.url}</p>
        <p><Tag color="green">{this.props.intl.formatMessage({ id: 'SportLive.streamKey' })}</Tag>
          {this.state.push.key}</p>
      </>
    );
  };

  startLiveLayout = () => (
    <>
      <Title level={3}>{this.state.eventTitle}</Title>
      <Space>
        <Button icon={<ApiOutlined />} shape="round" type="primary" danger onClick={_ => this.stopLive(true)}>
          {this.props.intl.formatMessage({ id: 'SportLive.stopLive' })}
        </Button>
        <Button
          type="primary"
          icon={this.state.showVideoPlayer ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={this.onChangeScreen}>
          {this.props.intl.formatMessage({ id: 'SportLive.screenSwitch' })}
        </Button>
        <Popover placement="bottom" content={this.content}>
          <Button icon={<KeyOutlined />}>
            {this.props.intl.formatMessage({ id: 'SportLive.streamKey' })}
          </Button>
        </Popover>
        <Button icon={<ClockCircleOutlined />} danger onClick={() => this.getMuteList()}>
          {this.props.intl.formatMessage({ id: 'SportLive.muteList' })}
        </Button>
      </Space>
      {this.state.showVideoAlert ?
        <Alert
          showIcon
          style={{ marginTop: '8px' }}
          message={this.props.intl.formatMessage({ id: 'SportLive.alartMessage' })}
          type="warning"
          closable />
        :
        null}
      <Row className="socket-control" gutter={[12, 12]}
        style={{ marginTop: '12px', padding: '4px', backgroundColor: 'white' }}>
        {this.state.showVideoPlayer ?
          <Col span={17}>
            <VideoPlayer
              channel={this.state.streamURL}
              roomType='normal'
              name={this.state.eventTitle}
              onError={this.flvOnError} />
          </Col> : null}
        <Col className="las" style={{ height: '93%' }} span={this.state.showVideoPlayer ? 7 : 24}>
          {
            this.state.managerList.length > 0 ?
              <Space size={0} align="top">
                <Tag color="#f50">{this.props.intl.formatMessage({ id: 'SportLive.manager' })}</Tag>
                <Avatar.Group maxCount={5}>
                  {this.state.managerList.map((m) => (
                    <Tooltip title={m.nickname} placement='top'>
                      <Avatar
                        style={{ cursor: 'pointer', backgroundColor: this.tranColor(m.nickname) }}
                        onClick={() => this.onClickItem(m)}>
                        {m.nickname.slice(0, 2)}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Space>
              :
              null
          }

          <SportLasChat
            msg={this.state.lasMsg}
            heart={this.state.lasHeart}
            manager={this.state.managerList}
            isReconnectVisible={this.state.isReconnectVisible}
            onClickItem={this.onClickItem} />
        </Col>
      </Row>
    </>
  );

  render() {
    const { user } = this.props;
    return (
      <>
        {user.liveRoomIsBegin ?
          this.startLiveLayout()
          :
          this.onAirDataLayout()}
        <ActionMenu
          isShow={this.state.showMenu}
          dataSource={this.state.customerData}
          manager={this.state.managerList}
          onChange={() => this.getManagerList(user.id)}
          onCancel={() => this.setState({ showMenu: false })} />
        <MuteList
          isShow={this.state.showMuteList}
          dataSource={this.state.muteList}
          reloadData={() => this.getMuteList()}
          onCancel={() => this.setState({ showMuteList: false })} />
      </>
    );
  }
}
const mapStateToProps = store => (
  { user: store.user }
);
export default connect(mapStateToProps)(injectIntl(Live));
